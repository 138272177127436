import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import DemoContributors from "../../components/contributors/DemoContributors1";

const TeamPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | Demo Contributors">
            <NavOne />
            {/* <PageHeader title="Demo Contributors" /> */}
            <DemoContributors />
            <Footer />
        </Layout>
    );
};

export default TeamPage;
